import "./SelectModeImage.css";

export default function SelectModeImage(props) {
  let {
    setImage,
    modelOne,
    selectModul,
    textureload,
    image,
    setSelectedImageButton,
    selectedImageButton,
  } = props;
  return (
    <>
      {/* <img
        src={image}
        onClick={() => {
          setImage(modelOne[selectModul], textureload.load(image));
        }}
      /> */}

      <div
        className={`select-image-box ${
          selectedImageButton == image && "selected-image"
        }`}
      >
        <div
          onClick={() => {
            setImage(modelOne[selectModul], textureload.load(image));
            setSelectedImageButton(image);
          }}
          className="select-image"
          style={{ background: `url("${image}") center center / cover` }}
        ></div>
        <div className="select-button"></div>
      </div>
    </>
  );
}
