import { useState } from "react";
import SelectModeImage from "../../Functions/SelectModeImage/SelectModeImage";
import "./RightPonel.css";
import * as THREE from "three";
let textureload = new THREE.TextureLoader();

export default function RightPonel(props) {
  let images = [
    "./images/5090.jpg",
    "./images/5091.jpg",
    "./images/5096.jpg",
    "./images/5128.jpg",
    "./images/9373.jpg",
    "./images/10014.jpg",
    "./images/log1.jpg",
    "./images/log2.jpg",
    "./images/log3.jpg",
    "./images/log4.jpg",
  ];
  let setImage = (model, img) => {
    let isGroup = model.isGroup;
    img.wrapS = THREE.RepeatWrapping;
    img.wrapT = THREE.RepeatWrapping;
    img.colorSpace = THREE.SRGBColorSpace;
    // img.minFilter = THREE.NearestFilter;
    console.log(img);

    if (isGroup) {
      let arr = model.children;

      arr.map((a, b) => {
        a.material.map = img;
      });
    } else {
      console.log(model);
      model.material.map = img;
    }
  };
  let {
    modelOne,
    selectModul,
    onLoading,
    selectedImageButton,
    setSelectedImageButton,
  } = props;
  // console.log(modelOne[selectModul]);

  return (
    <div className={`RightPonel ${onLoading > 1 && "loaded"}`}>
      <h1>Tanlang:</h1>
      <div className="images">
        {images.map((a, b) => {
          return (
            <SelectModeImage
              setSelectedImageButton={setSelectedImageButton}
              selectedImageButton={selectedImageButton}
              setImage={setImage}
              modelOne={modelOne}
              selectModul={selectModul}
              textureload={textureload}
              image={a}
            />
          );
        })}
        ;
      </div>
    </div>
  );
}
