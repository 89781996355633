import { useState } from "react";
import LeftPonel from "../Components/LeftPonel/LeftPonel";
import RightPonel from "../Components/RightPonel/RightPonel";
import Model from "../Model/Model";
import "./Main.css";
export default function Main(props) {
  let [modelOne, setModelOne] = useState();
  let [selectModul, setSelectModul] = useState(2);
  let [onLoading, setOnLoading] = useState(0);
  let [selectedImageButton, setSelectedImageButton] = useState("");

  let {} = props;

  return (
    <div className="MainPage">
      {/* <LeftPonel /> */}
      <Model
        setModelOne={setModelOne}
        modelOne={modelOne}
        setSelectModul={setSelectModul}
        setOnLoading={setOnLoading}
        setSelectedImageButton={setSelectedImageButton}
      />
      <RightPonel
        modelOne={modelOne}
        selectModul={selectModul}
        onLoading={onLoading}
        selectedImageButton={selectedImageButton}
        setSelectedImageButton={setSelectedImageButton}
      />
    </div>
  );
}
