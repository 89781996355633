import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import {
  BloomPass,
  FilmPass,
  GLTFLoader,
  OutputPass,
  RenderPass,
} from "three/examples/jsm/Addons.js";
import { OrbitControls } from "three/examples/jsm/Addons.js";
import { EffectComposer } from "three/examples/jsm/Addons.js";

export default function Model(props) {
  const useRefCon = useRef(null);
  let {
    setModelOne,
    modelOne,
    setSelectModul,
    setOnLoading,
    setSelectedImageButton,
  } = props;

  function animate() {
    requestAnimationFrame(animate);
    render.render(scene, camera);
  }

  // ! render
  const render = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    powerPreference: "low-power",
    stencil: true,
  });
  render.outputColorSpace = THREE.SRGBColorSpace;
  render.toneMapping = THREE.CineonToneMapping;
  render.toneMappingExposure = 1.5;
  render.setSize(window.innerWidth, window.innerHeight);

  // ! Scene
  const scene = new THREE.Scene();
  // ! Camera
  const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  camera.position.x = 10;
  camera.position.z = 10;
  camera.position.y = 10;

  // ! Controls
  const controls = new OrbitControls(camera, render.domElement);
  // controls.enableDamping = true;
  // controls.enablePan = false;
  controls.minDistance = 5;
  controls.maxDistance = 20;
  controls.minPolarAngle = 0.5;
  controls.maxPolarAngle = 1.5;
  controls.autoRotate = false;
  // controls.enableRotate = false;
  controls.target = new THREE.Vector3(-1, 1, 2);
  controls.update();

  // ! Lightings
  const ambientLight = new THREE.AmbientLight(0xffffff, 1);
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  scene.add(directionalLight);

  // const hemisphereLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 1);
  // scene.add(hemisphereLight);

  // const pointLight = new THREE.PointLight();
  // const pointLightHelper = new THREE.PointLightHelper(pointLight);
  // pointLight.position.set(0, 10, 10);
  // pointLight.distance = 40;
  // scene.add(pointLight);
  // scene.add(pointLightHelper);

  // const color = 0xffffff;
  // const intensity = 150;
  // const spotLight = new THREE.SpotLight(color, intensity);
  // spotLight.position.set(10, 10, 10);
  // const helperSpotLight = new THREE.SpotLightHelper(spotLight);
  // scene.add(helperSpotLight);
  // scene.add(spotLight);
  // scene.add(spotLight.target);

  // ! Edit texture
  useEffect(() => {
    const loader = new GLTFLoader().setPath("./model/");
    loader.load(
      "scene.gltf",
      (gltf) => {
        const mesh = gltf.scene;
        scene.add(mesh);
        let modelNumber1 = mesh.children[43];
        let modelNumber2 = mesh.children[44];
        let modelNumber3 = mesh.children[45];
        let modelNumber4 = mesh.children[46];
        let modelNumber5 = mesh.children[47];
        setModelOne([
          modelNumber1, //general_01 shkaf
          modelNumber2, //general_02 mini shakf
          modelNumber3, //general_03 stol rakvina
          modelNumber4, //general_04 devol rakvina
          modelNumber5, //general_05 stol adelni
        ]);
        // * Select modul
        const mouse = new THREE.Vector2();
        const raycaster2 = new THREE.Raycaster();
        document.body.addEventListener("click", (e) => {
          mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
          mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
          raycaster2.setFromCamera(mouse, camera);
          const model0Click = raycaster2.intersectObject(mesh.children[74]); //shkaf
          const model1Click = raycaster2.intersectObject(mesh.children[75]); //mini shkaf
          const model2Click = raycaster2.intersectObject(mesh.children[77]); //stol rakvina
          const model3Click = raycaster2.intersectObject(mesh.children[76]); //devol rakvina
          const model4Click = raycaster2.intersectObject(mesh.children[73]); //adelni stol

          if (model0Click.length > 0) {
            setSelectModul(0);
            setSelectedImageButton("");
          } else if (model1Click.length > 0) {
            setSelectModul(1);
            setSelectedImageButton("");
          } else if (model2Click.length > 0) {
            setSelectModul(2);
            setSelectedImageButton("");
          } else if (model3Click.length > 0) {
            setSelectModul(3);
            setSelectedImageButton("");
          } else if (model4Click.length > 0) {
            setSelectModul(4);
            setSelectedImageButton("");
          }
        });
        console.log("Tugadi");
        setOnLoading((left) => {
          console.log(left);

          return left + 1;
        });
      },
      () => {}
    );
  }, []);

  // ! rendering
  useEffect(() => {
    if (useRefCon.current) {
      useRefCon.current.appendChild(render.domElement);
    }
    animate();
  }, [useRefCon]);

  return (
    <div>
      <div className="model" ref={useRefCon}></div>
    </div>
  );
}
